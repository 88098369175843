export const cyrillic = {
  ca: {
    Аа: 'a',
    Бб: 'b',
    Вв: 'v',
    Гг: 'g',
    Дд: 'd',
  },
  cb: {
    Ее: 'ye',
    Ёё: 'yo',
    Жж: 'zh',
    Зз: 'z',
    Ии: 'i',
  },
  cc: {
    Йй: 'y',
    Кк: 'k',
    Лл: 'l',
    Мм: 'm',
    Нн: 'n',
  },
  cd: {
    Оо: 'o',
    Пп: 'p',
    Рр: 'r',
    Сс: 's',
    Тт: 't',
  },
  ce: {
    Хх: 'h',
    Цц: 'ts',
    Чч: 'ch',
    Шш: 'sh',
    Щщ: 'shsh',
  },
  cf: {
    Уу: 'oo',
    Фф: 'f',
    Ээ: 'e',
    Юю: 'yu',
    Яя: 'ya',
  },
};
