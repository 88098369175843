import { useEffect, useState } from 'react';
import _ from 'lodash';
import Header from './components/Header';
import Settings from './components/Settings';
import CharDisplay from './components/CharDisplay';
import { cyrillic } from './characters';
import CharInput from './components/CharInput';
import AnswerDisplay from './components/AnswerDisplay';
import StatisticsDisplay from './components/StatisticsDisplay';

function App() {
  const [totalAnswered, setTotalAnswered] = useState(0);
  const [totalCorrect, setTotalCorrect] = useState(0);
  const [totalIncorrect, setTotalIncorrect] = useState(0);
  const [showSettings, setShowSettings] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [rows, setRows] = useState([]);
  // Set a default just incase, probably not needed
  const [currentShown, setCurrentShown] = useState(['Аа', 'a']);
  const [seen, setSeen] = useState([]);
  const [selectedRows, setSelectedRows] = useState({
    ca: { isSelected: true },
    cb: { isSelected: false },
    cc: { isSelected: false },
    cd: { isSelected: false },
    ce: { isSelected: false },
    cf: { isSelected: false },
  });

  const changeSelectedRows = (key) => {
    let select = !selectedRows[key].isSelected;
    setSelectedRows({ ...selectedRows, [key]: { isSelected: select } });
  };

  useEffect(() => {
    let anyTrue = false;
    Object.entries(selectedRows).forEach(([key, isSelected]) => {
      if (Object.values(isSelected)[0] === true) {
        anyTrue = true;
      }
    });
    if (anyTrue === false) {
      setSelectedRows({ ...selectedRows, ca: { isSelected: true } });
    }
  }, [selectedRows]);

  useEffect(() => {
    let rowsToPush = [];
    Object.entries(selectedRows).forEach(([key, isSelected]) => {
      if (Object.values(isSelected)[0] === true) {
        Object.entries(cyrillic[key]).forEach((value) => {
          rowsToPush.push(value);
        });
      }
    });
    // let shuffledRowsToPush = _.shuffle(rowsToPush);
    setRows(rowsToPush);
  }, [selectedRows]);

  useEffect(() => {
    let toShow = _.sample(rows);
    if (typeof toShow !== 'undefined') {
      setCurrentShown(toShow);
    }
  }, [rows]);

  const next = () => {
    setSeen([...seen, currentShown]);
    if (!showAnswer) {
      setTotalCorrect(totalCorrect + 1);
    } else {
      setTotalIncorrect(totalIncorrect + 1);
    }
    setTotalAnswered(totalAnswered + 1);
    setShowAnswer(false);
  };

  useEffect(() => {
    let filteredRows = rows.filter((x) => !seen.includes(x));
    let toShow;
    if (filteredRows.length === 0) {
      setSeen([]);
      console.log('reached end');
      toShow = _.sample(rows);
    } else {
      toShow = _.sample(filteredRows);
    }
    if (typeof toShow !== 'undefined') {
      setCurrentShown(toShow);
    }
  }, [seen, rows]);

  const wrong = () => {
    setShowAnswer(true);
  };

  return (
    <div className="App container">
      <Header onAdd={() => setShowSettings(!showSettings)} />
      {showSettings && (
        <Settings
          selectedRows={selectedRows}
          changeSelectedRows={changeSelectedRows}
        />
      )}
      <div className="card p-5">
        <CharDisplay cyrillicChar={currentShown[0]}></CharDisplay>
        <CharInput
          correctOne={currentShown[1]}
          next={next}
          wrong={wrong}
        ></CharInput>
        {showAnswer && (
          <AnswerDisplay
            answer={currentShown[1]}
            currentChar={currentShown[0]}
          ></AnswerDisplay>
        )}
        <div className="d-flex justify-content-between mt-5">
          <p>Play sound coming soon...</p>
          <StatisticsDisplay
            correct={totalCorrect}
            total={totalAnswered}
          ></StatisticsDisplay>
        </div>
      </div>
    </div>
  );
}

export default App;
