import React from "react";

const Header = ({ onAdd }) => {
  return (
    <div className="mt-5 mb-4">
      <h1>Project Cyrillic</h1>
      <button className="btn btn-secondary btn-sm" onClick={onAdd}>
        Settings
      </button>
    </div>
  );
}

export default Header;
