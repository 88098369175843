import React from "react";

const CyrillicRow = ({ cyrillic, latin }) => {
  return (
    <td className="">
      <span className="cyrillic">{cyrillic}</span><br></br>
      <span className="latin">{latin}</span>
    </td>
  );
};

export default CyrillicRow;
