import React from "react";
import _ from "lodash";
import { cyrillic } from "../characters";
import CyrillicRow from "./CyrillicRow";

const Settings = ({selectedRows, changeSelectedRows}) => {
  let cZip = _.zip(
    Object.entries(cyrillic.ca),
    Object.entries(cyrillic.cb),
    Object.entries(cyrillic.cc),
    Object.entries(cyrillic.cd),
    Object.entries(cyrillic.ce),
    Object.entries(cyrillic.cf)
  );
  return (
    <div className="card card-body mb-4">
      <table className="text-center cyrillic-table">
        <thead>
          <tr>
            {Object.keys(cyrillic).map((key) => {
              return (
                <th key={key} className="bg-secondary">
                  <input type="checkbox" onChange={() => changeSelectedRows(key)} className="form-check-input" checked={selectedRows[key].isSelected}></input>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {cZip.map(([a, b, c, d, e, f], index) => {
            return (
              <tr key={index}>
                <CyrillicRow cyrillic={a[0]} latin={a[1]}></CyrillicRow>
                <CyrillicRow cyrillic={b[0]} latin={b[1]}></CyrillicRow>
                <CyrillicRow cyrillic={c[0]} latin={c[1]}></CyrillicRow>
                <CyrillicRow cyrillic={d[0]} latin={d[1]}></CyrillicRow>
                <CyrillicRow cyrillic={e[0]} latin={e[1]}></CyrillicRow>
                <CyrillicRow cyrillic={f[0]} latin={f[1]}></CyrillicRow>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Settings;
