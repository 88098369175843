import { useState, useEffect } from "react";

const CharInput = ({correctOne, next, wrong}) => {
  const [text, setText] = useState("");

  useEffect(() => {
    if (text.length === correctOne.length) {
      if (text === correctOne) {
        console.log("correct");
        setText('');
        next();
      } else {
        console.log("incorrect");
        wrong();
      }
    }
  }, [text, correctOne, next, wrong])

  return (
    <div className="text-center mt-5">
      <input
        className="form-control-sm text-center"
        onChange={(e) => setText(e.target.value)}
        value={text}
      ></input>
    </div>
  );
};

export default CharInput;
